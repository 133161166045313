<template>
  <div class="section-content pt-4" :class="{'mt-10' : $vuetify.breakpoint.xs , 'mt-n3' : $vuetify.breakpoint.sm}">
    <hb-header full class="spaces-header" :divider="false">
        <hb-page-header
            title="Spaces"
            description="Configure and manage spaces for the selected properties."
        >
        </hb-page-header>
    </hb-header>
    <!-- BCT: Added Action option 'add_spaces' in actions_panel -->
    <div style="flex: 1">
          <hb-report
            :key="report_key"
            report_type="spaces"
            :actions_panel="['advanced', 'master_filter', 'export', 'bulk_edit', 'columns', 'save', 'add_spaces']"
            show_views
            right_click
            row_click="unit_view"
            show_search
            @rowClicked="viewUnit"
            :show_default_report_option="true"
          ></hb-report>
      </div>
    </div>
</template>

<script type="text/babel">
    import HbReport from '../assets/HummingbirdReportViewer.vue';
    import { EventBus } from '../../EventBus.js';
    export default {
        name: "Units",
        data() {
            return {
                report_key: 0,
            }
        },
        created(){
            EventBus.$on('refetch_data', () => this.fetchData());
        },
        destroyed(){
            EventBus.$off('refetch_data', () => this.fetchData());
        },
        mounted(){},
        components:{
            HbReport
        },
        filters:{
        },
        computed:{
        },
        methods: {
            fetchData(){
              this.report_key++;
            },
            viewUnit(data){
                this.$store.dispatch('navigationStore/openSlideOut', {
                    component: 'unit',
                    props: {
                        unit_id: data.unit_id
                    }
                });
            }
        },


        watch: {
        }
    }
</script>

<style>


    .table-row{
        cursor: pointer;
    }

    .message-preview{
        height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: auto;
        display: block;
    }
    .table-cell{
        position: relative;
    }
    .unit{
        color: #00b2ce;
    }

    .table-row.disabled{
        opacity: .25;
    }




    .property-list-result .subdued,
    .property-list-result{
        line-height: 14px;
    }
    .blade-body-content {
        height: 45vh;
        overflow-y: scroll;
        max-height: 45vh;
    }
    .custom-border {
        border-bottom: 2px solid #dce8ef;
    }



</style>
